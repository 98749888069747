
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import Card from "primevue/card";
import AttachmentRecords from "@/components/Attachments/AttachmentRecords.vue";

export default defineComponent({
  name: "PayableAttachmentsTab",
  components: {
    Card,
    AttachmentRecords,
  },

  data() {
    return {
      id: this.$attrs.payableId,
    };
  },

  computed: {
    ...mapGetters({
      getActiveOrderTab: "accountingInquiry/getActiveOrderTab",
    }),
  },

  methods: {
    handleDeleted(event: any) {
      console.log("Deleted", event);
    },
    handleSaved(event: any) {
      console.log("Saved", event);
    },
  },
});
